{
  "name": "new-siamese-tech-admin-web",
  "version": "1.3.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.12",
    "@angular/common": "^17.3.0",
    "@angular/compiler": "^17.3.0",
    "@angular/core": "^17.3.0",
    "@angular/forms": "^17.3.0",
    "@angular/google-maps": "^17.2.2",
    "@angular/platform-browser": "^17.3.0",
    "@angular/platform-browser-dynamic": "^17.3.0",
    "@angular/router": "^17.3.0",
    "@auth0/angular-jwt": "^5.2.0",
    "@fullcalendar/angular": "^6.1.16",
    "@fullcalendar/core": "^6.1.15",
    "@fullcalendar/daygrid": "^6.1.15",
    "@fullcalendar/interaction": "^6.1.15",
    "@fullcalendar/timegrid": "^6.1.15",
    "angular-signature-pad-v2": "^8.0.0",
    "chart.js": "^4.4.0",
    "crypto-js": "^4.2.0",
    "primeflex": "^3.3.1",
    "primeicons": "^6.0.1",
    "primeng": "^17.14.1",
    "rxjs": "~7.8.0",
    "signature_pad": "^4.2.0",
    "tslib": "^2.3.0",
    "uuid": "^9.0.1",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.0",
    "@angular/cli": "^17.3.0",
    "@angular/compiler-cli": "^17.3.0",
    "@types/crypto-js": "^4.2.1",
    "@types/jasmine": "~5.1.0",
    "@types/uuid": "^9.0.8",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.2.2"
  }
}
